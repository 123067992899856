export const CallbackTopic = {
  topic: "Callback",
  type: "grandchildren",
  imageLink: "https://images.cheatsheet.cam/images/image-1726661465665.webp",
  tables: [
    {
      table: "Introduction",
    },
  ],
};
