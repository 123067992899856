export const PassDataTopic = {
  topic: "Pass Data",
  type: "children",
  imageLink: "https://images.cheatsheet.cam/images/image-1726468561925.webp",
  tables: [
    {
      table: "Introduction",
    },
  ],
};
