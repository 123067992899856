export const StateTopic = {
  topic: "State",
  type: "grandchildren",
  imageLink: "https://images.cheatsheet.cam/images/state.webp",
  tables: [
    {
      table: "Introduction",
    },
    {
      table: "Update",
    },
  ],
};
